/**
 * Service Value Method:  Provide visual indications what services to focus on given a list of participants with their
 *                        choices and additional decision criteria.
 *
 * @license AGPL-3.0-or-later
 *
 * Copyright (C) 2020 Thiemo Müller
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

.App-Rows .dropdown {
  float: right;
}

.App-Rows .Option {
  margin:2px 0;
}
.App-Rows .Type-Decision-Criterion .Row-Name {
  font-weight: bold;
}
.App-Rows .Type-Ignore .Row-Name {
  font-weight: bold;
  color: red;
}
.App-Rows .Type-Bubble-Sizes .Row-Name {
  font-weight: bold;
  color: blue;
}
