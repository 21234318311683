/**
 * Service Value Method:  Provide visual indications what services to focus on given a list of participants with their
 *                        choices and additional decision criteria.
 *
 * @license AGPL-3.0-or-later
 *
 * Copyright (C) 2020 Thiemo Müller
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

.App-Start table th,
.App-Start table td {
  border:1px solid #444;
  padding:5px 10px;
  text-align: left;
}

.App-Upload .Background {
  background: #32a852;
  color: #FFF;
  display: inline-block;
  width: 200px;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  line-height:40px;
}

.App-Upload .csv-reader-input {
  opacity: 0.001;
  margin:-40px 0 0 0;
  display: block;
}
.App-Upload .csv-reader-input input {
  cursor: pointer;
  width: 200px;
  height:40px;
  display: inline-block;
}

.Copyright {
  padding-top: 30px;
  color:#666;
}
